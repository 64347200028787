<template>
  <div class="tw-container">
    <!-- 購買訂閱方案 -->
    <div>
      <!-- 方案內容 -->
      <div v-if="mode === 'all'">
        <h4 class="tw-border-start fw-bold mb-3">選擇方案</h4>
        <div class="mb-3 d-flex">
          <div
            class="pay-plan-card me-3 pointer"
            v-for="data in payPlans"
            :key="data.id"
            @click="selectPayPlan(data)"
          >
            <img
              class="pay-plan-pushpin"
              src="@/assets/icon/pushpin.png"
              alt=""
            />
            <div>
              <p class="text-center tw-text-size18 fw-bolder pt-2">
                {{ data.name }}
              </p>
            </div>
            <hr />
            <div class="text-center">
              <p
                class="fw-bolder mb-3"
                :style="{
                  'text-decoration: line-through':
                    data.originPrice > data.finalPrice,
                }"
              >
                原價 NT${{ data.originPrice }}元
              </p>
              <p
                class="fw-bolder text-danger"
                v-if="data.originPrice > data.finalPrice"
              >
                優惠 NT${{ data.finalPrice }}元
              </p>
            </div>
            <div class="text-end" v-if="data.select">
              <img
                class="pay-plan-poke"
                src="@/assets/website-use/poke_L.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <hr />
      </div>
      <!-- 購買人資訊 -->
      <div>
        <h4 class="tw-border-start fw-bold mb-3">填寫發票收件資訊</h4>
        <div class="mb-3">
          <!-- 發票抬頭 -->
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              id="ReceiptTitle"
              placeholder="發票抬頭 或 收件人姓名"
              v-model="paymentRecord.title"
              maxlength="20"
            />
            <label for="ReceiptTitle">發票抬頭 或 收件人姓名(20字)</label>
          </div>
          <!-- 統一編號 -->
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              id="ReceiptBusinessAdminNumber"
              placeholder="統一編號"
              maxlength="8"
              v-model="paymentRecord.businessAdminNumber"
            />
            <label for="ReceiptBusinessAdminNumber">統一編號(8字)</label>
          </div>
          <!-- 電話 -->
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              id="ReceiptPhone"
              placeholder="電話"
              v-model="paymentRecord.phoneNumber"
              maxlength="15"
            />
            <label for="ReceiptPhone">電話(15字)</label>
          </div>
          <!-- 地址 -->
          <div class="row mb-1">
            <!-- 選擇縣市 -->
            <div class="form-floating col-md-6 col-12 mb-1 p-0">
              <select
                class="form-select"
                id="ReceiptCity"
                aria-label="選擇收件地址縣市"
                v-model="paymentRecord.city"
                @click="switchCleanPostalCode"
              >
                <option value="">不選擇</option>
                <option
                  :value="data.City"
                  v-for="(data, index) in city.data"
                  :key="index"
                >
                  {{ data.City }}
                </option>
              </select>
              <label for="ReceiptCity">選擇收件地址縣市</label>
            </div>
            <!-- 選擇區域 -->
            <div class="form-floating col-md-6 col-12 p-0">
              <select
                class="form-select"
                id="ReceiptArea"
                aria-label="選擇收件地址區域"
                v-model="paymentRecord.area"
              >
                <option value="" selected>不選擇</option>
                <option
                  :value="data.Zip5"
                  v-for="data in area.data"
                  :key="data.Zip5"
                >
                  {{ data.Area }}
                </option>
              </select>
              <label for="ReceiptArea">選擇收件地址區域</label>
            </div>
          </div>
          <!-- 街道/路名 -->
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              id="ReceiptAddress"
              placeholder="收件地址街道/路名"
              v-model="paymentRecord.address"
              maxlength="30"
            />
            <label for="ReceiptAddress">收件地址街道/路名(30字)</label>
          </div>
          <!-- 備註 -->
          <div class="form-floating">
            <textarea
              class="form-control"
              placeholder="備註"
              id="Note"
              style="height: 100px"
              maxlength="300"
              v-model="paymentRecord.note"
            ></textarea>
            <label for="Note">備註(300字)</label>
          </div>
        </div>
        <hr />
      </div>
      <!-- 推薦碼 -->
      <div v-if="!usedRecommendedCode && mode === 'all'">
        <h4 class="tw-border-start fw-bold mb-3">推薦碼</h4>
        <ul class="alert-danger fw-bolder p-3 mb-3">
          <li class="mb-2">1. 此帳號只能輸入一次推薦碼。</li>
          <li class="mb-2">
            2. 輸入推薦碼(推薦人的帳號)，點擊"驗證"，即可知道是否正確。
          </li>
          <li>3. 驗證成功後，雙方(你與推薦人)個別皆可獲得 1 個月的獎勵。</li>
        </ul>
        <div class="row mb-3">
          <div class="col-10 p-0">
            <div class="form-floating">
              <input
                type="email"
                class="form-control"
                id="Recommend"
                placeholder="name@example.com"
                v-model="paymentRecord.recommend"
              />
              <label for="Recommend">推薦碼 (推薦人帳號)</label>
            </div>
          </div>
          <div class="col-2 d-flex align-items-center">
            <button class="tw-btn tw-btn-success" @click="checkRecommend">
              驗證
            </button>
          </div>
        </div>
        <p class="fw-bolder" v-if="paymentRecord.recommend">
          驗證結果：
          <span
            v-if="recommendResult.result === null && !recommendResult.loading"
            >尚未驗證</span
          >
          <span class="text-warning" v-if="recommendResult.loading"
            >驗證中...</span
          >
          <template v-else>
            <span class="text-success" v-if="recommendResult.result === '成功'"
              >成功，系統會在您付費完後自動派發 1 個月的獎勵</span
            >
            <span class="text-danger" v-else>{{ recommendResult.result }}</span>
          </template>
        </p>
        <hr />
      </div>
    </div>
    <!-- 購買小幫手 -->
    <div v-if="remainingQuantity > 0 && mode === 'helper'">
      <ul class="list">
        <li class="tw-border-start fw-bolder">購買小幫手</li>
        <li>
          購買人數: <input type="text" v-model="littleHelper.quantity" /> /
          (尚可購買 {{ remainingQuantity }} 人)
        </li>
        <li>
          購買時長: <input type="text" v-model="littleHelper.period" /> 月 /
          <span v-if="littleHelper.originPrice !== littleHelper.finalPrice">
            <del>{{ littleHelper.originPrice }}元</del>&ensp;
            <span class="text-danger">{{ littleHelper.finalPrice }}元</span>
          </span>
          <span v-else>{{ littleHelper.finalPrice }}元</span>
        </li>
        <!-- <li>
          生效日期:
          <input
            type="datetime-local"
            placeholder="生效日期"
            v-model="littleHelper.enableTime"
          />
          (不填則以審核通過當天開始)
        </li> -->
      </ul>
      <hr />
    </div>
    <!-- 除值小幫手 -->
    <div v-if="participantPermissions.length > 0 && mode === 'helper'">
      <ul class="list">
        <li class="tw-border-start fw-bolder">儲值小幫手</li>
        <li
          class="row mb-2"
          v-for="data in participantPermissions"
          :key="data.id"
        >
          <span class="col-md-2 col-12 p-0 text-danger"
            >欄位ID: {{ data.id }}</span
          >
          <span class="col-md-5 col-12 p-0"
            >購買時長:
            <input type="text" v-model="data.period" @input="countTotalPrice" />
            月 /
            <span v-if="littleHelper.originPrice !== littleHelper.finalPrice">
              <del>{{ littleHelper.originPrice }}元</del>&ensp;
              <span class="text-danger">{{ littleHelper.finalPrice }}元</span>
            </span>
            <span v-else>{{ littleHelper.finalPrice }}元</span>
          </span>
          <!-- <span class="col-md-5 col-12 p-0"
            >生效日期:
            <input
              type="datetime-local"
              placeholder="生效日期"
              v-model="data.enableTime"
            />
            (不填則以審核通過當天開始)</span
          > -->
        </li>
      </ul>
      <hr />
    </div>
    <!-- 2023/04/30 前的優惠 -->
    <div v-if="mode === 'all' && selectDiscountPayplan">
      <h4 class="tw-border-start fw-bold mb-3">選擇贈送的優惠</h4>
      <template v-for="data in discountPlans" :key="data.id">
        <div class="form-check" v-if="currentPayPlan.id === data.for">
          <input
            class="form-check-input m-0 me-2"
            type="radio"
            name="discount20230430"
            :id="data.id"
            :value="data"
            v-model="selectDiscountPayplan"
          />
          <label class="form-check-label" :for="data.id">
            {{ data.value }}
          </label>
        </div>
      </template>
      <hr />
    </div>
    <!-- 金額確認 -->
    <div>
      <div>
        <h4 class="tw-border-start fw-bold mb-3">金額確認</h4>
        <ul>
          <li class="row mb-3 fw-bolder" v-if="mode === 'all'">
            <p class="col-4 p-0">方案費用</p>
            <p class="col-8 p-0">NT$ {{ this.paymentRecord.money }} 元</p>
          </li>
          <li class="row mb-3 fw-bolder" v-if="mode === 'helper'">
            <p class="col-4 p-0">小幫手費用</p>
            <p class="col-8 p-0">NT$ {{ littleHelper.totalPrice }} 元</p>
          </li>
          <li
            class="row mb-3 fw-bolder"
            v-if="participantPermissions.length > 0 && mode === 'helper'"
          >
            <p class="col-4 p-0">小幫手除值</p>
            <p class="col-8 p-0">NT$ {{ storedValueTotalPrice }} 元</p>
          </li>
          <li
            class="row mb-3 fw-bolder"
            v-if="mode === 'all' && selectDiscountPayplan"
          >
            <p class="col-4 p-0">2023-04-30 前贈送優惠</p>
            <p class="col-8 p-0">
              <span v-if="selectDiscountPayplan.id === 'threeHelper'"
                >贈送 3 個月/位 小幫手</span
              >
              <span v-else-if="selectDiscountPayplan.id === 'oneHelper'"
                >贈送 1 個月/位 小幫手</span
              >
              <span v-else>贈送一個月訂閱</span>
            </p>
          </li>
          <li class="row mb-3 fw-bolder">
            <p class="col-4 p-0">5% 營業稅</p>
            <p class="col-8 p-0">NT$ {{ this.fivePercent }} 元</p>
          </li>
          <hr />
          <li class="row mb-3 fw-bolder text-danger tw-text-size18">
            <p class="col-4 p-0">應付金額</p>
            <p class="col-8 p-0">NT$ {{ this.finalPrice }} 元</p>
          </li>
        </ul>
      </div>
    </div>
    <!-- 下一步 -->
    <div class="d-flex justify-content-between">
      <button
        @click="goAddLineAccountPage"
        class="text-white tw-btn tw-btn-success me-3 d-flex align-items-center"
      >
        <img
          src="@/assets/icon/line.png"
          alt="加入客服中心"
          height="25"
          border="0"
        />
        <span>加入客服中心</span>
      </button>
      <div>
        <button class="tw-btn tw-btn-success" @click="showModal('alert')">
          下一步
        </button>
      </div>
    </div>
  </div>
  <!-- 警告的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="alertModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">
            注意 ! 消費者權益，請詳閱
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <p
            class="alert-warning fw-bolder p-3"
            style="letter-spacing: 2px; line-height: 20px"
          >
            請在點擊<span class="text-success">確認</span
            >之前，檢查您所購買的項目是否正確，購買完一但
            <span class="text-danger">"成功開通後"</span>
            才察覺購買項目有問題，將無法改變已開通的方案。
          </p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button type="button" class="tw-btn tw-btn-success" @click="next">
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 空的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref=""
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">Title</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">...</div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button type="button" class="tw-btn tw-btn-success">確認</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// API function
import { GetCityArea } from '../../../methods/API/getCityArea'
import { parseToken, sortoutParseTokenResult } from '../../../methods/API/parseToken'

export default {
  data() {
    return {
      // modal
      alertModal: {},
      // data
      serverToken: '',
      mode: 'all', // all: 訂閱 + 小幫手，helper: 小幫手
      // 購買訂閱方案
      clearPostalCode: false,
      payPlans: [],
      currentPayPlan: null,
      userInfo: {},
      usedRecommendedCode: false,
      paymentRecord: {
        title: '',
        businessAdminNumber: '',
        phoneNumber: '',
        city: '',
        area: '',
        address: '',
        money: 6450,
        note: '',
        period: '12',
        recommend: ''
      },
      recommendResult: {
        loading: false,
        result: null, // null=尚未驗證
        userId: 0,
      },
      // 購買小幫手
      remainingQuantity: 0,
      littleHelper: {
        originPrice: 350,
        finalPrice: 350,
        quantity: 0,
        period: 1,
        enableTime: null,
        totalPrice: 0,
      },
      // 除值小幫手
      participantPermissions: [],
      storedValueTotalPrice: 0,
      // 最終金額
      fivePercent: 0,
      finalPrice: 0,
      // * 2023/04/30 前的優惠方案
      discountPlans: [],
      selectDiscountPayplan: null
    }
  },
  mixins: [GetCityArea],
  created() {
    this.initialization()
    this.getUserInfo()
    this.getCity()
  },
  mounted() {
    this.createModals(['alertModal'])
  },
  watch: {
    'paymentRecord.city'(val) {
      if (this.clearPostalCode) {
        this.paymentRecord.area = ''
      }
      this.getArea(val)
      this.clearPostalCode = false
    },
    'paymentRecord.phoneNumber'(val) {
      if ( !/^[0-9]*$/.test(val) ) {
        this.paymentRecord.phoneNumber = val.replace(/[^\d]/g, "");
      }
    },
    payPlans: {
      handler(val) {
        this.countTotalPrice()
      },
      deep: true
    },
    'littleHelper.quantity'(val) {
      if ( !/^[0-9]*$/.test(val) ) {
        this.littleHelper.quantity = val.replace(/[^\d]/g, "");
      }
      if (this.littleHelper.quantity > 10 ) this.littleHelper.quantity = 10
      this.countTotalPrice()
    },
    'littleHelper.period'(val) {
      if ( !/^[0-9]*$/.test(val) ) {
        this.littleHelper.period = val.replace(/[^\d]/g, "");
      }
      this.countTotalPrice()
    },
    // * 2023/04/30 前的優惠方案
    selectDiscountPayplan: {
      handler(val) {
        this.countTotalPrice()
      },
      deep: true
    },
  },
  methods: {
    initialization() {
      this.serverToken = this.$methods.getCookie('serverToken')
      if (!this.$route.query.mode) {
        alert('請按正常方式進入此頁')
        return this.$router.push({name: 'PaymentRecord'})
      }
      this.mode = this.$route.query.mode
    },
    showModal(status) {
      if (status === 'alert') {
        this.alertModal.show()
      }
    },
    // 選擇方案
    selectPayPlan(payPlan) {
      this.payPlans.forEach(item => {
        if (item.id === payPlan.id) {
          item.select = true
          this.currentPayPlan = item
        }
        else item.select = false
      })
      this.discount20230430('initialization')
    },
    switchCleanPostalCode() {
      this.clearPostalCode = true
    },
    // 檢查是否使用過推薦碼
    // 取得使用者 ID
    getUserInfo() {
      this.$methods.switchLoading('show')
      const result = parseToken(this.serverToken)
      result.then(res => {
        const result = sortoutParseTokenResult(res)
        this.getPhysicalUserInfo(result)
      }).catch(err => {
        console.log(err.responseJSON)
        this.SweetAlert('other', err.responseJSON.message)
        this.$methods.switchLoading('hide')
      })
    },
    // 取得實體使用者資訊
    getPhysicalUserInfo(userInfo) {
      console.log(userInfo)
      const vm = this
      const getPhysicalUserInfoApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [
        {
          type: 1,
          ids: userInfo.ids,
          methods: '{getParticipantPermissions,isUsedRecommendedCode,getPaymentRecords{isPaid,getPaymentRecordProjectLinks{getSubscriptionProject}}}'
        }
      ]
      this.participantPermissions = []
      $.ajax({
        type: 'POST',
        async: true,
        url: getPhysicalUserInfoApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const userInfo = res.data[0].objects[0]
            vm.usedRecommendedCode = userInfo.usedRecommendedCode
            vm.userInfo = userInfo
            const participantPermissions = userInfo.participantPermissions.length
            vm.remainingQuantity = 10 - participantPermissions
            userInfo.participantPermissions.forEach(item => {
              item.period = 0
              item.enableTime = null
            })
            vm.participantPermissions = userInfo.participantPermissions
            // 檢查是否繼續使用優惠
            vm.checkIsEligible(userInfo)
            // * 2023-04-30 前的優惠
            vm.discount20230430('initialization')
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 檢查此顧客是否有資格有訂閱優惠
    checkIsEligible(userInfo) {
      this.payPlans = [{
        id: 'A1',
        name: '一年期 (12個月)',
        period: 12,
        originPrice: 8600,
        finalPrice: 8600,
        select: true
      },{
        id: 'A2',
        name: '三個月',
        period: 3,
        originPrice: 2700,
        finalPrice: 2700,
        select: false
      }]
      let uneligible = false
      let hasDiscount2022 = false
      const discount2022 = new Date("2022/12/31 23:59:59") * 1 // 2022 優惠
      if (userInfo.paymentRecords && userInfo.paymentRecords.length > 0) {
        // 排序 (舊 ~ 新)
        userInfo.paymentRecords.sort((a, b) => {
          const a1 = new Date(a.createTime) * 1
          const b1 = new Date(b.createTime) * 1
          return a1 - b1
        })
        console.log(userInfo.paymentRecords)
        // 先檢查在 2022 年是否有訂閱過，有的話都會是當年度優惠價格
        hasDiscount2022 = userInfo.paymentRecords.some(item => {
          if (item.paid && !item.deleted && item.paymentRecordProjectLinks.length > 0) {
            const createTime = new Date(item.createTime) * 1
            if (item.planType !== 'FREE_TRIAL' && createTime <= discount2022) return true
          }
        })
        // 檢查過往訂閱是否有斷過
        let recordStartTime = null
        let recordEndTime = null
        uneligible = userInfo.paymentRecords.some((item, index, arr) => {
          const createTime = new Date(item.createTime) * 1
          if (item.planType !== 'FREE_TRIAL') {
            if (item.paid && !item.deleted && item.paymentRecordProjectLinks.length > 0) {
              const currentStartTime = item.paymentRecordProjectLinks[0].subscriptionProject.enableTime
              const currentEndTime = item.paymentRecordProjectLinks[0].subscriptionProject.expirationTime
              // 判斷剛剛紀錄的上一個訂閱區間
              if (recordStartTime && recordEndTime) {
                const now = new Date() * 1
                // 判斷目前訂閱方案是否到期，若到期了在做下面判斷
                if (now > recordEndTime) {
                  
                  if (createTime <= recordEndTime) {
                    console.log('目前為止訂閱沒斷過')
                    // 紀錄目前訂閱起始
                    recordStartTime = currentStartTime
                    // 紀錄目前訂閱結束
                    recordEndTime = currentEndTime
                  }
                  else {
                    console.log('此訂閱未在上個訂閱期間續約，以中斷過')
                    console.log(item)
                    return true
                  }
                } else console.log('這個訂閱紀錄還沒到期')
              } else {
                // 紀錄目前訂閱起始
                recordStartTime = currentStartTime
                // 紀錄目前訂閱結束
                recordEndTime = currentEndTime
              }
            }
          }
        })
      } else uneligible = true
      // 如果符合資格，繼續沿用優惠價格
      console.log(hasDiscount2022, uneligible)
      if (hasDiscount2022 && !uneligible) {
        this.payPlans = [{
          id: 'A1',
          name: '一年期 (12個月)',
          period: 12,
          originPrice: 8600,
          finalPrice: 6450,
          select: true
        },{
          id: 'A2',
          name: '三個月',
          period: 3,
          originPrice: 2700,
          finalPrice: 2100,
          select: false
        }]
      }
      this.currentPayPlan = this.payPlans[0]
    },
    // 驗證推薦碼
    checkRecommend() {
      if (!this.paymentRecord.recommend) return this.SweetAlert('other', '您尚未輸入推薦碼')
      const vm = this
      const checkRecommendApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [
        {
          query: {"columns":[{"columnName":"id","siteObjectType":1}],"distinct":true,"group":{"groups":[{"column":{"columnName":"account","siteObjectType":1},"operator":{"operator":"=","type":1,"value":this.paymentRecord.recommend}}],"operator":1},"joins":[],"siteObjectType":1,"sqlCommandType":1},
          methods: '{getCurrentSubscriptionProject{isTrialPeriod}}'
        }
      ]
      this.recommendResult = {
        loading: true,
        result: null,
        userId: 0
      }
      $.ajax({
        type: 'POST',
        async: true,
        url: checkRecommendApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const userInfo = res.data[0].objects
            vm.sortOutRecomment(userInfo)
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.recommendResult.result = null
          vm.recommendResult.loading = false
        },
      })
    },
    // 整理驗證結果
    sortOutRecomment(userInfo) {
      if (userInfo.length > 0) {
        if (this.userInfo.id === userInfo[0].id) this.recommendResult.result = '您輸入的帳號與目前的帳號相符，固不得使用'
        else {
          if (!userInfo[0].currentSubscriptionProject) this.recommendResult.result = '您所輸入的帳號目前尚未開通賣場'
          else if (userInfo[0].currentSubscriptionProject.trialPeriod) this.recommendResult.result = '您所輸入的帳號目前還在試用期內，固不得使用'
          else {
            const now = new Date() * 1
            console.log(userInfo[0].currentSubscriptionProject && userInfo[0].currentSubscriptionProject.enableTime <= now && now <= userInfo[0].currentSubscriptionProject.expirationTime)
            if (userInfo[0].currentSubscriptionProject && userInfo[0].currentSubscriptionProject.enableTime <= now && now <= userInfo[0].currentSubscriptionProject.expirationTime) {
              this.recommendResult.result = '成功'
              this.recommendResult.userId = userInfo[0].id
            }
            else this.recommendResult.result = '此帳號尚未在訂閱期間內'
          }
        }
      }
      else this.recommendResult.result = '系統尚未有此帳號的存在'
      this.recommendResult.loading = false
    },
    // 前往加入客服中心
    goAddLineAccountPage() {
      window.open('https://lin.ee/uejzVgr', "_blank");
    },
    // 計算訂閱總價
    countSubTotalPrice() {
      this.paymentRecord.period = this.currentPayPlan.period
      this.paymentRecord.money = this.currentPayPlan.finalPrice
    },
    // 計算小幫手總價
    countLittleHelperTotalPrice() {
      const quantity = this.littleHelper.quantity ? this.littleHelper.quantity : 0
      const period = this.littleHelper.period ? this.littleHelper.period : 0
      const price = this.littleHelper.finalPrice
      this.littleHelper.totalPrice = quantity * period * price
    },
    // 計算除值小幫手
    countStoredValueTotalPrice() {
      this.storedValueTotalPrice = 0
      this.participantPermissions.forEach(item => {
        if ( !/^[0-9]*$/.test(item.period) ) {
          item.period = item.period.replace(/[^\d]/g, "");
        }
        const period = item.period ? item.period : 0
        this.storedValueTotalPrice += period * this.littleHelper.finalPrice
      })
    },
    // 計算總價
    countTotalPrice() {
      this.countSubTotalPrice()
      this.countLittleHelperTotalPrice()
      this.countStoredValueTotalPrice()
      const subTotalPrice = this.mode === 'all' ? this.paymentRecord.money : 0
      const helperPrice = this.littleHelper.totalPrice
      const storedValuePrice = this.storedValueTotalPrice
      // * 2023-04-30 前的優惠
      const discount = this.discount20230430('countDiscount')
      // 計算最後金額
      console.log(subTotalPrice, helperPrice, storedValuePrice, discount)
      this.fivePercent = Math.round((subTotalPrice + helperPrice + storedValuePrice - discount) * 0.05)
      this.finalPrice = subTotalPrice + helperPrice + storedValuePrice - discount + this.fivePercent
    },
    next() {
      // * 檢查若選擇贈送小幫手: 1. 確定小幫手總價值 >= 贈送的，2. 備註 > 新增優惠資訊
      let note = ''
      if (this.selectDiscountPayplan) {
        const helperPrice = this.littleHelper.totalPrice
        const storedValuePrice = this.storedValueTotalPrice
        const totalPrice = helperPrice + storedValuePrice
        // if (this.selectDiscountPayplan.id === 'oneHelper' && (totalPrice / 250) === 0) return this.SweetAlert('other', '您選擇的小幫手總金額小於折扣的金額')
        // else if (this.selectDiscountPayplan.id === 'threeHelper' && (totalPrice / 250) < 3) return this.SweetAlert('other', '您選擇的小幫手總金額小於折扣的金額')
        // 新增備註
        note = '系統備註:\n' + `2023-04-30前的優惠活動，${this.selectDiscountPayplan.value}` + '\n'
      }

      const vm = this
      const paymentRecordApi = `${process.env.VUE_APP_API}/paymentRecord/create`
      const header = {
        authorization: this.serverToken
      }
      let object = {
        userReceiptInfo: {
          title: this.paymentRecord.title,
          businessAdminNumber: this.paymentRecord.businessAdminNumber,
          phoneNumber: this.paymentRecord.phoneNumber,
          postalCode: this.paymentRecord.area,
          address: this.paymentRecord.address
        },
        money: this.finalPrice,
        note: note + (this.paymentRecord.note ? `顧客備註:\n${this.paymentRecord.note}` : ''),
      }
      if (this.mode === 'all') {
        // 訂閱方案
        object.projectLinks = [{
          period: this.paymentRecord.period
        }]
        // * 2023-04-30 優惠方案
        if (this.selectDiscountPayplan && this.selectDiscountPayplan.id === 'oneMonth') object.projectLinks[0].period ++
        // 有使用推薦碼
        if (this.recommendResult.userId) object.recommenderId = this.recommendResult.userId
      }
      if (this.mode === 'all' || this.mode === 'helper') {
        // 有購買小幫手
        object.permissionLinks = []
        if (this.littleHelper.quantity && this.littleHelper.period) {
          for(let i=0; i<this.littleHelper.quantity; i++) {
            object.permissionLinks.push({
              period: this.littleHelper.period,
              participantPermissionId: null,
              enableTime: new Date(this.littleHelper.enableTime) * 1 ? new Date(this.littleHelper.enableTime) * 1 : null
            })
          }
        }
        // * 2023-04-30 優惠方案 (如果他沒有小幫手可以除值，那贈送的三個月就幫他開一個格子 3 個月)
        if (this.selectDiscountPayplan && this.participantPermissions.length === 0) {
          if (this.selectDiscountPayplan.id === 'threeHelper') {
            object.permissionLinks.push({
              period: 3,
              participantPermissionId: null,
              enableTime: null
            })
          } else if (this.selectDiscountPayplan.id === 'oneHelper') {
            object.permissionLinks.push({
              period: 1,
              participantPermissionId: null,
              enableTime: null
            })
          }
        }
        // 要除值小幫手
        this.participantPermissions.forEach((item, index) => {
          // * 2023-04-30 優惠方案
          let totalPeriod = parseInt(item.period)
          if (this.selectDiscountPayplan && index === 0) {
            if (this.selectDiscountPayplan.id === 'threeHelper') totalPeriod += 3
            else if (this.selectDiscountPayplan.id === 'oneHelper') totalPeriod += 1
          }
          if (totalPeriod > 0) {
            object.permissionLinks.push({
              period: totalPeriod,
              participantPermissionId: item.id,
              enableTime: item.enableTime ? new Date(item.enableTime) * 1 : null
            })
          }
        })
      }
      let data = []
      data.push(object)
      console.log(data)
      $.ajax({
        type: 'POST',
        async: true,
        url: paymentRecordApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if(res.code === '200') {
            vm.alertModal.hide()
            const paymentNotification = res.data[0].paymentNotification
            const paymentRecord = res.data[0].paymentRecord
            vm.$router.push({
              name: 'MoneyTransfer',
              query: {
                id: paymentNotification.id,
                money: paymentRecord.money
              },
            })
          }
        },
        error: function(err) {
          console.log(err)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
        },
      })
    },
    // * 2023-04-30 前的優惠
    discount20230430(status) {
      const now = new Date() * 1
      const discountDeadLine = new Date("2023/04/30 23:59:59") * 1 // 2023/04/30 前購買小幫手 > 優惠
      if (status === 'initialization') {
        if (now >= discountDeadLine) return
        this.littleHelper.finalPrice = 250
        // 初始化
        if (this.mode === 'all') {
          // 初始化贈送優惠
          this.discountPlans = [
            {
              id: 'oneHelper',
              value: '贈送 1 個月/位 小幫手',
              for: 'A2', // 對應的訂閱 ID
            },
            {
              id: 'threeHelper',
              value: '贈送 3 個月/位 小幫手',
              for: 'A1',
            },
            {
              id: 'oneMonth',
              value: '贈送一個月訂閱',
              for: 'A1',
            },
          ]
          if (this.currentPayPlan.id === 'A1') this.selectDiscountPayplan = this.discountPlans[1]
          else this.selectDiscountPayplan = this.discountPlans[0]
        }
      } else if (status === 'countDiscount') {
        // 折扣小幫手金額
        // 優惠過期
        if (now >= discountDeadLine) return 0
        // 只單純買小幫手頁面
        if (!this.selectDiscountPayplan) return 0
        // 訂閱 + 小幫手頁面
        if (this.selectDiscountPayplan.id === 'threeHelper') return 0
        else if (this.selectDiscountPayplan.id === 'oneHelper') return 0
        else return 0
      }
    }
  },
}
</script>